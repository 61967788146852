import { getAccessToken } from '@/utils/amplify/getAccessToken';

const customFetch: (
  input: RequestInfo,
  init?: RequestInit,
  token?: string,
) => Promise<Response> = async (input, init) => {
  try {
    const accessToken = await getAccessToken();

    init = init || {};
    init.headers = {
      ...init.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  } catch (error) {
    console.error('Error getting session', error);
  }

  return fetch(input, init);
};

export default customFetch;
