import { Configuration } from '@univearth/lifti-api/dist';

import customFetch from './customFetch';

const config = new Configuration({
  basePath: process.env.NEXT_PUBLIC_API_BASE_PATH,
  fetchApi: customFetch,
});

export default config;
