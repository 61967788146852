import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .lowercase()
    .email('正しいメールアドレスを入力してください。')
    .required('メールアドレスを入力してください'),
  password: yup
    .string()
    .min(8, 'パスワードは8文字以上で入力してください。')
    .max(128, 'パスワードは128文字以下で入力してください。')
    .matches(/^[\x20-\x7E]*$/, 'パスワードはASCII文字である必要があります。')
    .matches(/\d/, 'パスワードには少なくとも1つの数字を含めてください。')
    .matches(/[a-z]/, 'パスワードには少なくとも1つの小文字を含めてください。')
    .matches(/[A-Z]/, 'パスワードには少なくとも1つの大文字を含めてください。')
    .matches(
      /[!@#%&\\/\,><':;\|_~\`\=\+\-\^\$\*\.\[\]\{\}\(\)\?\" ]/,
      'パスワードには少なくとも1つの特殊文字を含めてください。',
    )
    .required('パスワードを入力してください'),
  remember: yup.boolean(),
});
